import { css } from 'styled-components';

import { base } from './fonts';
import COLORS from './colors';
import BREAKPOINTS from './breakpoints';

const rem = (px) => `${px / base}rem`;

const styles = {
  display: css`
    font-weight: 400;
    font-size: ${rem(72)};
    line-height: 121.25%;
    letter-spacing: -0.015em;

    ${BREAKPOINTS.max.medium`
      font-size: ${rem(48)};
      `}

    ${BREAKPOINTS.max.small`
      font-size: ${rem(36)};
      `}
  `,
  hero: css`
    font-weight: 600;
    font-size: ${rem(48)};
    line-height: 128.5%;
    letter-spacing: -0.015em;

    ${BREAKPOINTS.max.small`
      font-size: ${rem(28)};
      `}
  `,
  h1: css`
    font-weight: 600;
    font-size: ${rem(48)};
    line-height: 120%;
    letter-spacing: -0.015em;
    ${BREAKPOINTS.max.small`
      font-size: ${rem(28)};
      `}
  `,
  h2: css`
    font-weight: 600;
    font-size: ${rem(36)};
    line-height: 128%;
    letter-spacing: -0.015em;
    ${BREAKPOINTS.max.small`
      font-size: ${rem(24)};
      `}
  `,
  h3: css`
    font-weight: 600;
    font-size: ${rem(18)};
    line-height: 128%;
    letter-spacing: -0.015em;
    ${BREAKPOINTS.max.small`
      font-size: ${rem(18)};
      `}
  `,
  subheading: css`
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: 156%;
    letter-spacing: -0.02em;
  `,
  preheading: css`
    font-weight: 600;
    font-size: ${rem(12)};
    line-height: 156%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  `,
  p1: css`
    font-weight: 400;
    font-size: ${rem(16)};
    line-height: 156%;
    letter-spacing: -0.02em;
    color: ${COLORS.shades.s400.css};
  `,
  p2: css`
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: 156%;
    letter-spacing: -0.02em;
    color: ${COLORS.shades.s400.css};
  `,
  p3: css`
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: 128%;
    letter-spacing: -0.02em;
  `,
  caption: css`
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: 156%;
    letter-spacing: -0.02em;
  `,
  nav: css`
    font-weight: 400;
    font-size: ${rem(14)};
    line-height: 137.75%;
    letter-spacing: -0.02em;
  `,
  footer: css`
    font-weight: 400;
    font-size: ${rem(12)};
    line-height: 152%;
    letter-spacing: -0.02em;
  `,
  small: css`
    font-weight: 400;
    font-size: ${rem(10)};
    line-height: 156%;
    letter-spacing: -0.02em;
  `,
  quote: css`
    font-weight: 400;
    font-size: ${rem(24)};
    line-height: 156%;
    letter-spacing: -0.02em;
    ${BREAKPOINTS.max.small`
      font-size: ${rem(18)};
      `}
  `,
  strong: css`
    font-weight: 400;
    font-size: ${rem(13)};
    line-height: 128%;
    letter-spacing: -0.02em;
  `,
  quoteSmall: css`
    font-weight: 400;
    font-size: ${rem(13)};
    line-height: 156%;
    letter-spacing: -0.02em;
  `,
};

export default styles;
